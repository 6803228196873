<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <c-card title="개선내용 및 효과" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!--저장-->
              <c-btn
                v-show="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="param.suggestion"
                mappingType="PUT"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveData"
                @btnCallback="saveCallback" />
              <c-btn
                v-show="editable && !disabled"
                :url="completeUrl"
                :isSubmit="isComplete"
                :param="param.suggestion"
                mappingType="PUT"
                label="완료"
                icon="check"
                @beforeAction="completeData"
                @btnCallback="completeCallback" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="개선내용"
                name="boardImprove"
                v-model="param.suggestion.suggestionImprove">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="유형효과"
                name="boardTypeEffect"
                v-model="param.suggestion.suggestionTypeEffect">
              </c-textarea>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                :rows="4"
                label="무형효과"
                name="boardIntangibleEffect"
                v-model="param.suggestion.suggestionIntangibleEffect">
              </c-textarea>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <!--첨부파일-->
              <c-upload 
                :attachInfo="param.attachImprInfo"
                :editable="editable && !disabled"
                label="첨부파일">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'suggestionImpr',
  props: {
    param: {
      type: Object,
      default: () => ({
        saiSugSuggestionId: '',
        suggestion: {
          saiSugSuggestionId: '',
          suggestionStepCd: '',
          suggestionNo: '',
          plantCd: '',
          regDeptCd: '',
          execDeptCd: '',
          suggestionDate: '',
          suggestionStatus: '',
          suggestionTitle: '',
          suggestionProblem: '',
          suggestDivisionTypeCd: null,
          suggestCategoryTypeCd: null,
          suggestionSuggest: '',
          suggestionBenefit: '',
          suggestionImprove: '',
          suggestionLocation: '',
          suggestionFirstAuditCd: '',
          suggestionSecondAuditCd: '',
          suggestionFirstAuditOpinion: '',
          suggestionSecondAuditOpinion: '',
          suggestionTypeEffect: '',
          suggestionIntangibleEffect: '',
          bountyFirst: '',
          bountySecond: '',
          sysApprovalRequestId: '',
          approvalStatusCd: '',
          vendorCd: '',
          approvalRequestFlag: '',
          regUserId: '',
          chgUserId: '',
        },
        attachImprInfo: {
          isSubmit: '',
          taskClassCd: 'SOB_BOARD_IMPR',
          taskKey: '',
        },
      }),
    },
  },
  data() {
    return {
      saveUrl: '',
      completeUrl: '',
      editable: true,
      isSave: false,
      isComplete: false,
      
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    disabled() {
      return this.editable 
        && ((this.param.suggestion.execDeptCd !== this.$store.getters.user.deptCd
        && this.param.suggestion.regDeptCd !== this.$store.getters.user.deptCd)
        || this.param.suggestion.suggestionStepCd !== 'SSSSC00003')
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sai.sug.suggestion.update.url;
      this.completeUrl = transactionConfig.sai.sug.suggestion.complete.url;
      // code setting
      // list setting 
    },
    saveData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',    // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?     // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.suggestion.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.param.saiSugSuggestionId = result.data
    },
    completeData() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.param.suggestion.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('emitStep', {
        name: 'getDetail',
        param: { 
          saiSugSuggestionId: this.param.saiSugSuggestionId
        },
      })
    },
  }
};
</script>
